import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//data
import dataPartnersPage from "data/dataPartnersPage";
import dataNewsPage from "data/dataNewsPage";

//data temporal
import Esker from '../images/logos_partners/Logo_Esker_Certified_Partner-C.png'

//header
import Header, { NavLink,  PrimaryLink as  LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light";

//content
import Home from 'components/hero/HeaderPages'
import News from "components/cards/News";
import Footer from "components/footers/RequestFooter";

const Divider = tw.div`my-4 border-b-2 border-gray-500 w-full`;

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => {

  return (
    <StyledDiv>
      <Container>
        <StyledHeader />
        <Home
          heading = { dataNewsPage.header.heading }
          subheading = { dataNewsPage.header.subheading }
          imgSrc= { dataNewsPage.header.headerImg }
        />
        <News
          heading = {
            <>
              { dataNewsPage.news.heading }
              <span tw="text-red-700">
                { dataNewsPage.news.headingSpan }
              </span>
            </>
          }
          description = { dataNewsPage.news.description }
          linkText = { dataNewsPage.news.linkText }
          cardLinkText = { dataNewsPage.news.cardLinkText }
          textOnLeft = { dataNewsPage.news.textOnLeft }
          cards = { dataNewsPage.news.cards }
        />
        <Footer />
      </Container>
    </StyledDiv>
  );
};

// Partner ESKER: Esker, a worldwide leader in AI-driven process automation solutions and pioneer in cloud computing.

// Copiar logo de ESKER y debajo poner:
// Esker’s automation platform complements REQUEST SA solution portfolio and will allow REQUEST SA to provide its customers and prospects with the most comprehensive offer.
