import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import IRAM from "../../images/request/request_iram.png";
import REQUEST from "../../images/request/logoRequestBlanco-Transparente.png";
import EskerCP from "../../images/logos_partners/Logo_Esker_Certified_Partner-C_blanco.png";

const Container = tw.div`relative bg-gray-800 text-gray-700 -mb-8 -mx-8 py-12 lg:pt-4 lg:pb-10 mt-32`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

const Divider = tw.div`my-4 border-b-2 border-gray-300 w-11/12 mx-auto`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-around pb-12 sm:px-24 sm:py-0`;

const LogoContainer = tw.div`flex  items-center justify-center md:justify-start `;
const LogoImg = tw.a`w-40 md:w-64 m-8`;
const IramImg = tw.img`w-32 md:w-40 md:m-8`;
const SocialLinksContainer = tw.div`md:mt-0 flex`;

const LinkList = tw.ul`flex flex-col items-center md:grid md:grid-cols-3 md:grid-rows-2 mt-6 text-xs md:text-sm font-medium mx-12 md:mx-auto`;
const LinkListItem = tw.li`py-2 px-2 mx-auto text-center`;
const Link = tw.a`text-gray-400 pb-1 transition duration-300`;

export default () => {
  return (
    <Container>
      <Content>
        <ThreeColRow>
          <LogoContainer>
            <IramImg src={IRAM}></IramImg>
          </LogoContainer>
          <SocialLinksContainer>
            <LogoImg tw="m-4 w-40" href="https://www.esker.com/">
              <img src={EskerCP} alt="logo" />
            </LogoImg>
          </SocialLinksContainer>
        </ThreeColRow>
        <Divider />
        <LinkList>
          <LinkListItem tw="row-span-2 row-start-1">
            <SocialLinksContainer>
              <LogoImg href="/requestsa" tw="w-full h-full m-0">
                <img src={REQUEST} alt="logo" />
              </LogoImg>
            </SocialLinksContainer>
          </LinkListItem>
          <LinkListItem>
            <Link>&copy; Request Information Technology.<br></br>All Rights Reserved.</Link>
          </LinkListItem>
          <LinkListItem>
            <Link>info@request.com.ar</Link>
          </LinkListItem>
          <LinkListItem tw="md:col-start-3">
            <Link>Tel: +54 11 4545 8333</Link>
          </LinkListItem>
          <LinkListItem tw="md:row-start-2">
            <Link>Machaín 4289 Piso 1<br></br>Ciudad Autonoma de Buenos Aires - Argentina</Link>
          </LinkListItem>
        </LinkList>
      </Content>
    </Container>
  );
};
