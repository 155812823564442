import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//data
import dataRealCaseStudy from "data/dataRealCaseStudy";

//Componentes
import Home from 'components/hero/HeaderPages'
import InfoComponent from "components/cards/InfoComponent";
import Info from "components/cards/Info";
import Footer from "components/footers/RequestFooter";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light";



const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Link = styled.a`
${tw`text-blue-500 hocus:border-b-2 hocus:border-blue-400`}`

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;


export default () => {

  return (
    <StyledDiv>
      <Container>
        <StyledHeader />
        <Home
          heading = {dataRealCaseStudy.siemens.heading}
          subheading = { dataRealCaseStudy.siemens.subheading }
          imgSrc = { dataRealCaseStudy.siemens.headerImg }
        />
        <div tw="pt-4 pb-10 md:py-12">
        <Info 
          description= {
            <div tw="mx-5 sm:mx-0">
              <span tw="text-secondary-500 font-semibold">MIDDLETON, Wis. — May 25, 2021 —</span>
              <Link href="https://www.esker.com/">Esker</Link>, a <Link href="https://www.esker.com/technology-solutions/cloud-technology-platform/?utm_source=business-wire&utm_medium=referral&utm_campaign=Siemens%20Healthineers%20&utm_content=press-release">global cloud</Link> platform and leader in <Link href="https://www.esker.com/technology-solutions/artificial-intelligence/?utm_source=business-wire&utm_medium=referral&utm_campaign=Siemens%20Healthineers%20&utm_content=press-release">AI-driven process automation</Link> solutions for finance and customer service functions, today announced that <Link href="https://www.siemens-healthineers.com/">Siemens Healthineers</Link>, an international medical technology company, is automating its ORDER MANAGEMENT process with Esker’s <Link href="https://www.esker.com/business-process-solutions/order-cash/order-processing-automation-software/?utm_source=business-wire&utm_medium=referral&utm_campaign=Siemens%20Healthineers%20&utm_content=press-release">ORDER MANAGEMENT</Link> solution.
            
            </div>
          }
        />
        <InfoComponent
          subheading = { dataRealCaseStudy.siemens.info.subheading }
          description = { dataRealCaseStudy.siemens.info.description[2] }
        />
        <InfoComponent
          heading = { dataRealCaseStudy.siemens.info.heading[0]}
          description = { dataRealCaseStudy.siemens.info.description[3] }
        />
        <Info 
          heading = { dataRealCaseStudy.siemens.info.heading[1] }
          description= {
            <div tw="mx-5 sm:mx-0">
              Esker is a global cloud platform built to unlock strategic value for finance and customer service professionals, and strengthen collaboration between companies by automating the cash conversion cycle. Esker’s solutions incorporate technologies like Artificial Intelligence (AI) to drive increased productivity, enhanced visibility, reduced fraud risk, and improved collaboration with customers, suppliers and internally. Esker operates in North America, Latin America, Europe and Asia Pacific with global headquarters in Lyon, France, and U.S. headquarters in Madison, Wisconsin. For more information on Esker and its solutions, visit <Link href="https://www.esker.com/">www.esker.com</Link>. Follow Esker on Twitter <Link href="https://twitter.com/eskerinc">@EskerInc</Link> and join the conversation on the Esker blog at <Link href="https://blog.esker.com/?utm_source=Visibility&utm_medium=MediaRelations&utm_campaign=PRs">blog.esker.com</Link>.
            
            </div>
          }
        />
        </div>
        <Footer />
      </Container>
    </StyledDiv>
  );
};