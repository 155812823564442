/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from 'react';
// import "./iframe.css"


export default ({ url, background="#FFFFFF", className="video" }) => {
  return (
    <div
      className={className}
      style={{
        position: "relative",
        background: "none",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 73,
        height: 0,
        backgroundColor: "white"
      }}
      
    >
      <iframe
        title="Embeded Video"
        style={{
          position: "absolute",
          background: "none",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "white"
        }}
        allowtransparency="true"
        src={url}
        frameBorder="0"
      />
    </div>
  );
};
