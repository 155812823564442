import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";


const Container = tw.div`relative mx-5`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-2xl mx-auto pt-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full max-w-screen-md p-2`;
const Subheading = tw(SubheadingBase)`w-full font-bold text-2xl text-center mb-4`;
const Description = tw(SectionDescription)`w-full text-center max-w-screen-lg mt-0`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 flex flex-col justify-center `}
`;

const Card = styled.div`
  ${tw`flex flex-row justify-center text-center`}
  .imageContainer {
    ${tw`flex text-center rounded-full h-auto`}
    img {
      ${tw`w-6 h-auto`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2 p-2`}
  }

  .title {
    ${tw`font-semibold tracking-wide text-xl leading-none mb-8 uppercase`}
  }

  .description {
    ${tw`text-secondary-200 `}
  }
`;

// const imageContainer = tw.img`mt-10`

export default ({
  heading = "",
  description = "",
  imgSrc = null,
  cardsLeft = null,
  cardsRight = null
}) => {


  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <Column tw="max-w-xs">
        {cardsLeft.map((card, i) => (
            <Card tw="sm:text-right" key={i}>
              <span className="textContainer">
                <span className="title"><a href={card.url}>{card.title}</a></span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
        ))}
        </Column>
        <Column tw="p-4">
          <img src={imgSrc}></img>
        </Column>
        <Column tw="max-w-xs">
        {cardsRight.map((card, i) => (
            <Card tw="sm:text-left" key={i}>
              <span className="textContainer">
                <span className="title"><a href={card.url}>{card.title}</a></span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
        ))}
        </Column>
      </ThreeColumnContainer>
    </Container>
  );
};
