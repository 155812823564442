import background from '../images/backgrounds/customers.jpg'

//imgs carousel
//ORDEN de los PRIMEROS
// YPF
// Ingredion
// Aliaxis
// Havas
// Multiradio
// Ecogas

import Agrofina from '../images/logos_customers/Agrofina.jpg'
import Aliaxis from '../images/logos_customers/Aliaxis.jpg'
import AustinPowder from '../images/logos_customers/AustinPowder.jpg'
import AveryDennison from '../images/logos_customers/AveryDennison.jpg'
import DelMonte from '../images/logos_customers/DelMonte.jpg'
import DigitalHouse from '../images/logos_customers/DigitalHouse.jpg'
import Disney from '../images/logos_customers/Disney.jpg'
import DomingoAlonso from '../images/logos_customers/DomingoAlonso.jpg'
import Ecogas from '../images/logos_customers/Ecogas.jpg'
import Emasal from '../images/logos_customers/Emasal.jpg'
import Getty from '../images/logos_customers/Getty.jpg'
import Havas from '../images/logos_customers/Havas.jpg'
import Honda from '../images/logos_customers/Honda.jpg'
import Ingredion from '../images/logos_customers/Ingredion.jpg'
import KimberlyClark from '../images/logos_customers/KimberlyClark.jpg'
import LaNacion from '../images/logos_customers/LaNacion.jpg'
import Multiradio from '../images/logos_customers/Multiradio.jpg'
import Pirelli from '../images/logos_customers/Pirelli.jpg'
import RedSurcos from '../images/logos_customers/RedSurcos.jpg'
import Rheem from '../images/logos_customers/Rheem.jpg'
import Roca from '../images/logos_customers/Roca.jpg'
import SCJohnson from '../images/logos_customers/SCJohnson.jpg'
import Seidor from '../images/logos_customers/Seidor.jpg'
import Siemens from '../images/logos_customers/Siemens.jpg'
import Sinergium from '../images/logos_customers/Sinergium.jpg'
import TyCSports from '../images/logos_customers/TyCSports.jpg'
import WarnerMedia from '../images/logos_customers/WarnerMedia.jpg'
import YPF from '../images/logos_customers/YPF.jpg'

export var dataCustomersPage = {
    headerHeading: "Customers",
    headerSubheading: "",
    headerImg: background,
    carousel: {
        heading: "We have collaborated with the success of these companies",
        subheading: "STRENGTHENING CUSTOMER RELATIONSHIPS & EXCEEDING AUTOMATION GOALS",
        description: "",
        rubro_heading: [
            "Energy & Utilities",
            "Farming & Food",
            "Life Sciences",
            "Manufacturing",
            "Multimedia",
            "Professional Services",
            "Retail/Wholesale"
        ],
        images: {
            energy_utilities: [
                {
                    id: 1,
                    src: AustinPowder,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Ecogas,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: YPF,
                    alt: "Image 1"
                },
            ],
            farming_food: [
                {
                    id: 1,
                    src: Agrofina,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: DelMonte,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Ingredion,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: RedSurcos,
                    alt: "Image 1"
                },
            ],
            lifeSciences: [
                {
                    id: 1,
                    src: Siemens,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Sinergium,
                    alt: "Image 1"
                },
            ],
            manufacturing: [
                {
                    id: 1,
                    src: Honda,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Pirelli,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Roca,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Aliaxis,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Emasal,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Rheem,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: AveryDennison,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: DomingoAlonso,
                    alt: "Image 1"
                },
            ],
            multimedia: [
                {
                    id: 1,
                    src: Disney,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Getty,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Havas,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: LaNacion,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: TyCSports,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: WarnerMedia,
                    alt: "Image 1"
                },
            ],
            professionalServices: [
                {
                    id: 1,
                    src: Seidor,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: DigitalHouse,
                    alt: "Image 1"
                }
            ],
            retail_wholesale: [
                {
                    id: 1,
                    src: KimberlyClark,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: Multiradio,
                    alt: "Image 1"
                },
                {
                    id: 1,
                    src: SCJohnson,
                    alt: "Image 1"
                }
            ],
        }
    }
};

export default dataCustomersPage;