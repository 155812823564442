import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import dataMainPage from '../data/dataMainPage'
import ProfessionalServices from "components/features/CardsWithServices";

import img from '../images/backgrounds/professionalservices.jpg'

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light";

import Home from 'components/hero/HeaderPages'
import Footer from "components/footers/RequestFooter";




//Componente con navbar
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;

  const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => {

  return (
    <StyledDiv>
      <Container>
        <StyledHeader/>
        <Home 
          heading = "Professional Services"
          subheading = ""
          imgSrc={img}
        />
        <ProfessionalServices
          heading = {
            <>
              { dataMainPage.professionalServices.heading }
              <span tw="text-red-700">
                { dataMainPage.professionalServices.headingSpan }  
              </span> 
            </>
          }
          cards = { dataMainPage.professionalServices.cards }
        />
        <Footer />
      </Container>
    </StyledDiv>
  );
};
