import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//Data

import dataCustomersPage from "data/dataCustomersPage";

import Home from "components/hero/HeaderPages";

import CarouselComponent from "components/carousel/CarouselComponent";
import Footer from "components/footers/RequestFooter";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../components/headers/light";

//Componente con navbar

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Divider = tw.div`my-4 border-b-2 border-gray-400 w-full`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8`}
`;
const CarouselContainer = styled.div`
  ${tw`max-w-screen-xl mx-8 md:mx-auto -mt-16`}
`;

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => {
  return (
    <StyledDiv>
      <Container>
        <StyledHeader />
        <Home
          heading={dataCustomersPage.headerHeading}
          subheading={dataCustomersPage.headerSubheading}
          imgSrc={dataCustomersPage.headerImg}
        />
        {/* prueba */}
        <CarouselComponent
          heading={dataCustomersPage.carousel.heading}
          subheading={dataCustomersPage.carousel.subheading}
          description={dataCustomersPage.carousel.description}
          imgs={[]}
        />
        <CarouselContainer>
          <CarouselComponent
            headingCarousel={dataCustomersPage.carousel.rubro_heading[0]}
            description=""
            imgs={dataCustomersPage.carousel.images.energy_utilities}
            settings={{
              slidesToShow: 3,
              responsive: [
                {
                  breakpoint: 650,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
              ],
            }}
          />
          <Divider />
        </CarouselContainer>
        <CarouselContainer>
          <CarouselComponent
            headingCarousel={dataCustomersPage.carousel.rubro_heading[1]}
            description=""
            imgs={dataCustomersPage.carousel.images.farming_food}
            settings={{
              slidesToShow: 4,
              responsive: [
                {
                  breakpoint: 650,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
              ],
            }}
          />
          <Divider />
        </CarouselContainer>
        <CarouselContainer>
          <CarouselComponent
            headingCarousel={dataCustomersPage.carousel.rubro_heading[2]}
            description=""
            imgs={dataCustomersPage.carousel.images.lifeSciences}
            settings={{
              slidesToShow: 2,
              responsive: [
                {
                  breakpoint: 650,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
              ],
            }}
          />
          <Divider />
        </CarouselContainer>
        <CarouselContainer>
          <CarouselComponent
            headingCarousel={dataCustomersPage.carousel.rubro_heading[3]}
            description=""
            imgs={dataCustomersPage.carousel.images.manufacturing}
          />
          <Divider />
        </CarouselContainer>
        <CarouselContainer>
          <CarouselComponent
            headingCarousel={dataCustomersPage.carousel.rubro_heading[4]}
            description=""
            imgs={dataCustomersPage.carousel.images.multimedia}
          />
          <Divider />
        </CarouselContainer>
        <CarouselContainer>
          <CarouselComponent
            headingCarousel={dataCustomersPage.carousel.rubro_heading[5]}
            description=""
            imgs={dataCustomersPage.carousel.images.professionalServices}
            settings={{
              slidesToShow: 2,
              responsive: [
                {
                  breakpoint: 650,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
              ],
            }}
          />
          <Divider />
        </CarouselContainer>
        <CarouselContainer>
          <CarouselComponent
            headingCarousel={dataCustomersPage.carousel.rubro_heading[6]}
            description=""
            imgs={dataCustomersPage.carousel.images.retail_wholesale}
            settings={{
              slidesToShow: 3,
              responsive: [
                {
                  breakpoint: 650,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    infinite: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: true,
                    autoplay: true,
                    autoplaySpeed: 1500,
                  },
                },
              ],
            }}
          />
        </CarouselContainer>
        <Footer />
      </Container>
    </StyledDiv>
  );
};
