import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { MainPageDescription } from 'components/misc/Typography'


import rei from "../../images/add_ons_titles/logo_rei.png"
import cot from "../../images/add_ons_titles/logo_cot.png"
import apocriphal from "../../images/add_ons_titles/logo_apocriphal.jpg"

const Container = tw.div`relative mx-5 -mt-32 sm:mt-0`;
const TwoColumn = tw.div`flex flex-col md:flex-row sm:justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`h-0 w-0 relative md:w-5/12 md:h-auto md:visible sm:mt-16`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-40 md:h-64 `
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const ImgHeading = tw.img`w-auto h-16 mr-4 my-auto`
const Subheading = tw(SubheadingBase)`sm:text-left text-left text-3xl md:text-left text-secondary-500`;
const Heading = tw(
  SectionHeading
)` md:text-left text-3xl leading-tight sm:pb-2 max-w-screen-sm`;
// const Heading = tw(SectionHeading)`w-full max-w-screen-sm text-center p-2`;
const Description = tw(MainPageDescription)`text-left`;
// const Description = tw.p`mt-4 md:text-left text-sm md:text-base lg:text-lg text-secondary-200 max-w-screen-sm`;
// export const SectionDescription = tw.p`mt-0 text-sm md:text-base lg:text-lg text-secondary-200 max-w-xl`;
const ListTitle = tw.p`mt-4 md:text-left text-sm md:text-base lg:text-lg leading-relaxed text-secondary-500`;

// const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
// const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
// const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-gray-800 tracking-wide`;
// const Key = tw.div`font-medium text-red-700`;
// const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0 bg-red-700 hocus:bg-red-500`;



export default ({
  subheading = "",
  heading = "",
  description = "",
  descriptionDos = "",
  descriptionTres = "",
  descriptionCuatro = "",
  imageLogo = null,
  imageSrc = null,
  imageCss = null,
  imageContainerCss = null,
  imageInsideDiv = true,
  textOnLeft = false,
  listTitle = "",
  list = []
}) => {

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>

        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <div tw="flex">
              {imageLogo && <ImgHeading src={imageLogo}></ImgHeading>}
              <Heading>{heading}</Heading>
            </div>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Description>{description}</Description>
            <Description>{descriptionDos}</Description>
            <Description>{descriptionTres}</Description>
            <Description>{descriptionCuatro}</Description>
            <ListTitle>{listTitle}</ListTitle>

            {list.map((item, index) => (
                  <li tw="list-decimal text-gray-700 pb-2" key={index}>
                    <span>{item}</span>
                  </li>
                ))}
                
          </TextContent>
        </TextColumn>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
        </ImageColumn>
      </TwoColumn>
    </Container>
  );
};
