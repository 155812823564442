import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import "./Header.css"

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";


const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-200`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`


const Container = styled.div`
  ${tw`relative flex -mx-8 -mt-8 bg-center bg-cover h-screen sm:h-1/2 sm:min-h-144`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div` absolute inset-0 bg-gray-800 opacity-50 `;

const HeroContainer = tw.div`z-10 relative sm:px-8 mx-auto flex flex-col content-end`;
const Content = tw.div`flex flex-1 flex-col justify-center content-end`;

//Modificar los tamaños del titulo

const Heading = styled.h1`
  ${tw` text-center font-bold font-sans leading-none lg:leading-tight text-white mb-8 z-20 text-5xl`}
  span {
    ${tw`inline-block`}
  }
`;

const Subheading = styled.h1`
${tw`text-2xl text-center sm:text-xl md:text-2xl lg:text-3xl text-white font-sans`}`
  ;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 font-bold shadow-lg transition duration-300 text-gray-100 
hocus:text-gray-200 focus:outline-none focus:shadow-outline
bg-gradient-to-r from-red-700 to-red-900 hocus:to-red-500 transition duration-300 ease-out hocus:ease-in`;

export default ({
  heading = "",
  subheading = ""
}) => {

  return (
    <Container>
      <StyledHeader />
      <br/>
      <OpacityOverlay />
      <HeroContainer>
        <Content>
          <Heading>{heading}</Heading>
          <div tw="flex justify-center content-end">
            <div tw="absolute bg-gray-900 w-11/12 pt-20 sm:pt-8 lg:pt-12 opacity-75"></div>
            <Subheading tw="absolute ">{subheading}</Subheading>
          </div>
          {/* <div>
            <Subheading tw="absolute ">{subheading}</Subheading>
            <div tw="absolute bg-gray-900 -ml-8 w-11/12 pt-12 opacity-75"></div>
          </div> */}
        </Content>
      </HeroContainer>
    </Container>
  );
};
