import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";


const Container = tw.div`relative mx-5 sm:mx-0`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center mx-auto pt-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full font-bold max-w-screen-sm p-5 leading-tight text-center`;
const Subheading = tw(SubheadingBase)`w-full font-bold text-2xl text-center mb-4`;
const Description = tw(SectionDescription)`w-full text-center max-w-screen-lg mt-0`;

const VerticalSpacer = tw.div`sm:mt-10 w-full`

const Column = styled.div`
  ${tw`sm:w-2/3 flex flex-col sm:flex-row px-2 pt-4`}
`;
// md:w-1/3 lg:w-1/3 flex flex-row
const Card = styled.div`
  ${tw`flex flex-row justify-center sm:text-left h-full`}
  .imageContainer {
    ${tw`flex text-center rounded-full `}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`text-center sm:text-left`}
  }

  .title {
    ${tw`font-bold tracking-wide text-lg leading-none mb-8 text-center sm:text-left`}
  }

  .subtitle {
    ${tw`font-bold text-red-700 tracking-wide text-lg leading-none mb-8`}
  }

  .description {
    ${tw`mt-2 text-sm md:text-base lg:text-base text-secondary-300 max-w-xl`}
  }

  .list {
    ${tw`text-secondary-200`}
  }
`;

// const imageContainer = tw.img`mt-10`

export default ({
    heading = "",
    subheading = "", 
    description = "",
    cards = null,
}) => {


  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <Column>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <span className="subtitle">{card.span}</span>
                <p tw="flex flex-col" className="description">
                  {card.description}
                </p>
                <br/>
                  <ul tw="-mt-4 text-sm pl-2 text-left ">
                  {card.list.map((item, index) => (
                    <li tw="list-none text-secondary-200 pl-10 sm:pl-0" key={index}>
                      <span>{item}</span>
                    </li>
                  ))}
                  </ul>
              </span>
            </Card>
          </Column>
        ))}
        </Column>
      </ThreeColumnContainer>
    </Container>
  );
};