import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import AliaxisCase from "../images/real_case_study/Aliaxis.jpg"
import DomingoAlonsoCase from "../images/real_case_study/DomingoAlonso.jpg"
import EmasalCase from "../images/real_case_study/Emasal.jpg"
import SiemensHealthineersCase from "../images/real_case_study/SiemensHealthineers.jpg"

import Tripters from '../images/news/tripters_card.png'
import background from '../images/news/news.jpeg'

import {ruta} from '../helpers/rutaServidor'
const rutaServidor = ruta

export var dataNewsPage = {
    header: {
        heading: "News",
        subheading: "",
        headerImg: background,
    },
    news: {
        heading: "our next",
        headingSpan: " projects",
        cardLinkText: "Read More",
        textOnLeft: false,
        cards: [
            {
              imageSrc: Tripters,
              company: "Tripters",
              type: "FULL STACK DEVELOPMENT",
              title: '"We will enable everyone to travel the world."',
              buttonLink: `${rutaServidor + '/tripters'}`
            },
        ]
    }
};
export default dataNewsPage
