import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

//estilos para 3 tarjetas
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`mt-16 lg:w-1/3`;

const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);


const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const HeadingTitle = tw(SectionHeading)`w-full text-center leading-tight`;

const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`lg:mx-4 xl:mx-4 max-w-sm flex flex-col h-full`;

const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`sm:h-96 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4 p-1`;

const CardHeader = tw.div`flex justify-between items-center  h-12`;
const CardCompany = tw.div`text-primary-700 max-w-xs font-bold text-xl w-1/3`;
const CardType = tw.div`font-semibold text-sm text-left text-gray-600 w-1/2 pl-10`;

const CardTitle = tw.h5`text-xl mt-3 font-semibold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-4`;

//estilos para 4 tarjetas
// const Container = tw.div`relative`;
// const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

// const ThreeColumn = tw.div`flex flex-wrap`;
// const Column = tw.div`mt-16 lg:w-1/4`;


// const HeadingInfoContainer = tw.div`flex flex-col items-center`;

// const HeadingTitle = tw(SectionHeading)`w-full text-center leading-tight`;

// const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
// const PrimaryLink = styled(PrimaryLinkBase)`
//   ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
//   svg {
//     ${tw`ml-2 w-5 h-5`}
//   }
// `;

// const Card = tw.div`lg:mx-4 xl:mx-4 max-w-sm flex flex-col h-full`;

// const CardImage = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`md:h-72 sm:w-auto bg-cover bg-center rounded`
// ]);

// const CardText = tw.div`mt-4`;

// const CardHeader = tw.div`flex justify-between items-center h-12`;
// const CardCompany = tw.div`text-primary-700 max-w-xs font-bold text-lg w-1/3`;
// const CardType = tw.div`font-semibold text-sm text-left text-gray-600 w-1/3`;

// const CardTitle = tw.h5`text-xl mt-2 font-semibold`;

// const CardAction = tw(PrimaryButtonBase)`w-full mt-4`;

export default ({
  subheading = "",
  heading = "",
  description = "",
  linkText = "",
  cardLinkText = "",
  cards = null,
}) => {
  
  return (
    <Container>
      <Content>
          <HeadingInfoContainer>
            <HeadingTitle>{heading}</HeadingTitle>
            <Subheading>{subheading}</Subheading>
            {description && <HeadingDescription>{description}</HeadingDescription>}
            {linkText && <PrimaryLink>
              {linkText}
            </PrimaryLink>}
          </HeadingInfoContainer>
        <ThreeColumn>
          
          {cards.map((card, index) => (
            <Column key={index}>
              <Card>
                <CardImage style={{aspectRatio: "1/1"}} imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.company}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  {card.salto ? <div tw="pt-1"><br/></div> : <></>}
                  
                </CardText>
                  <a href={card.buttonLink}><CardAction>{cardLinkText}</CardAction></a>
              </Card>
            </Column>
          ))}

        </ThreeColumn>
      </Content>
    </Container>
  );
};
