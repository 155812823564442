import bgSiemens from '../images/news/bgtripters.png'



export var dataNewsPage = {
    tripters: {
        heading: "Tripters",
        subheading: "Tripters and Request S.A. Sign Memorandum Of Understanding to Accelerate Software Development Services",
        headerImg: bgSiemens,
        info: {
            heading: {

            },
            subheading: "7/29/2022",
            description: [
                
                "SILICON VALLEY, California --  Tripters and Request S.A., a leader in software development services based in Buenos Aires, Argentina, today announced that they have entered into a collaboration agreement to accelerate the development of Tripters’ online software platform.",
                "The agreement marks a major milestone in the successful partnership of both companies since the Fall of 2021, and is a testament to the business case and value proposition offered by Tripters.",
                '“Request S.A. is one of the premier software development companies in Argentina, a location that has established itself as one of the main hubs for software engineering talent in the world,” said Tripters’ Founder & CEO Andrew Prillwitz. “We are confident that our joint efforts will significantly improve and accelerate the development of our platform.”',
                "Last year $4.1 billion of venture capital investment flowed into Latin America, exceeding South East Asia’s $3.3 billion and beating Africa, the Middle East, and central and Eastern Europe combined, according to the Global Private Capital Association.",
                '“We believe that Tripters has a unique and distinct value proposition and is well positioned to become a disruptor in the travel industry,” said Daniel Burgardt, CEO at Request S.A. “We are very excited to formalize our collaboration and partner with Tripters in the development of its platform.”',
            ],
            links: "https://tripters.com."
        }
    }
}

export default dataNewsPage