import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//Data
import dataEskerSolutionsPages from "data/dataEskerSolutionsPages";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light";

//Component
import Home from 'components/hero/HeaderPages'
import VideoSection from "components/cards/ThreeColVideoCenter";
import Footer from "components/footers/RequestFooter";


const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => {

  return (
    <StyledDiv>
      <Container>
        <StyledHeader />
        <Home
          heading = { dataEskerSolutionsPages.procureToPay.home.heading }
          subheading = { dataEskerSolutionsPages.procureToPay.home.subheading }
          imgSrc = { dataEskerSolutionsPages.procureToPay.home.imgSrc }
        />

        <VideoSection
          heading = {
            <>
              <span tw="text-red-700">
                { dataEskerSolutionsPages.procureToPay.videoSection.headingSpan }
              </span>
              { dataEskerSolutionsPages.procureToPay.videoSection.heading }
            </>
          }  
          description = { dataEskerSolutionsPages.procureToPay.videoSection.description }
          urlVideo = { dataEskerSolutionsPages.procureToPay.videoSection.urlVideo }
          cardsLeft = { dataEskerSolutionsPages.procureToPay.cards.cardsLeft }
          cardsRight = { dataEskerSolutionsPages.procureToPay.cards.cardsRight }
        />

        <Footer />
      </Container>
    </StyledDiv>
  );
};
