import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

//estilos con 3 tarjetas
const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full leading-tight`;

const Column = styled.div`
  ${tw`md:w-1/4 lg:w-1/4 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-gray-600 rounded-lg mt-12 shadow-md`}
  .imageContainer {
    ${tw` border-gray-800 text-center rounded-full p-4 flex-shrink-0 relative`}
    img {
      ${tw`w-32 h-auto`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center `}
  }

  .title {
    ${tw`mt-20 font-bold text-xl leading-none text-red-700`}
  }

  .description {
    ${tw`mt-4 text-sm md:text-base lg:text-lg text-secondary-200 max-w-screen-sm`}
  }
`;




export default ({
  heading = "",
  cards = null,
}) => {

  return (
    <Container >
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
