import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line


import bgSiemens from '../images/real_case_study/Siemens_Healthineers_Banner.jpg'
import bgAliaxis from '../images/real_case_study/Aliaxis_Banner.jpg'
import bgEmasal from '../images/real_case_study/Emasal_Banner.jpg'
import bgDomingoAlonso from '../images/real_case_study/Domingo_Alonso_Banner.jpg'



const Link = styled.a`
${tw`text-blue-500 hocus:border-b-2 hocus:border-blue-400`}`



export var dataRealCaseStudy = {
    siemens: {
        heading: "Siemens Healthineers",
        subheading: "Siemens Healthineers Enhances Its ORDER MANAGEMENT Process with Esker’s AI-Driven Solution",
        headerImg: bgSiemens,
        info: {
            heading: ["ABOUT SIEMENS HEALTHINEERS","ABOUT ESKER"],
            subheading: "REQUEST SA, as an Esker’s CERTIFIED PARTNER, was in charge of delivering the implementation and consulting services.",
            description: [
                [
                    "MIDDLETON, Wis. — May 25, 2021 —"
                ],
                [
                    `${<Link href="https://www.esker.com/">Esker</Link>}, a ${<Link href="https://www.esker.com/technology-solutions/cloud-technology-platform/?utm_source=business-wire&utm_medium=referral&utm_campaign=Siemens%20Healthineers%20&utm_content=press-release">global cloud</Link>} platform and leader in ${<Link href="https://www.esker.com/technology-solutions/artificial-intelligence/?utm_source=business-wire&utm_medium=referral&utm_campaign=Siemens%20Healthineers%20&utm_content=press-release">AI-driven process automation</Link>} solutions for finance and customer service functions, today announced that ${<Link href="https://www.siemens-healthineers.com/">Siemens Healthineers</Link>}, an international medical technology company, is automating its order management process with Esker’s ${<Link href="https://www.esker.com/business-process-solutions/order-cash/order-processing-automation-software/?utm_source=business-wire&utm_medium=referral&utm_campaign=Siemens%20Healthineers%20&utm_content=press-release">Order Management</Link>} solution.`
                ],
                [
                    "As part of its strategy to improve business processes, Siemens Healthineers had been searching for a global automation solution to overhaul ORDER MANAGEMENT for its laboratory diagnostics products while also seamlessly integrating with its SAP® ERP system.",
                    "While fulfilling orders in a timely manner is critical for suppliers in any industry, the in-vitro diagnostics products that Siemens Healthineers delivers to medical institutions must be processed quickly and accurately to ensure the best care for patients and enhance their entire experience. The previously cumbersome order entry process relied on the manual entry of order lines. To decrease the workload of the Customer Service team as well as reduce errors, Siemens Healthineers turned to Esker for a solution that would automate, standardize and streamline its ORDER MANAGEMENT process.",
                    "Prior to Esker, only 8% of orders were automated. Today, Siemens Healthineers automates 85% of received orders, with 93% of order fields correctly identified by the solution. The advanced data recognition capabilities of Esker’s AI Engine has enabled Siemens Healthineers to benefit from high recognition rates after just a short period of self-learning."
                ],
                [
                    "Siemens Healthineers’ goal is to support healthcare professionals through expanding precision medicine, transforming care pathways and improving the patient experience. Globally, an estimated 5 million patients benefit daily from technology and service innovations in diagnostic and therapeutic imaging, laboratory diagnostics and molecular medicine as well as Digital Health and Enterprise Services. A leading medical technology company with over 170 years of experience and 18,000 patents globally and more than 48,000 dedicated resources in 70 countries, Siemens Healthineers continues to innovate and shape the future of healthcare."
                ],
                [
                    `Esker is a global cloud platform built to unlock strategic value for finance and customer service professionals, and strengthen collaboration between companies by automating the cash conversion cycle. Esker’s solutions incorporate technologies like Artificial Intelligence (AI) to drive increased productivity, enhanced visibility, reduced fraud risk, and improved collaboration with customers, suppliers and internally. Esker operates in North America, Latin America, Europe and Asia Pacific with global headquarters in Lyon, France, and U.S. headquarters in Madison, Wisconsin. For more information on Esker and its solutions, visit ${<Link href="https://www.esker.com/">www.esker.com</Link>}. Follow Esker on Twitter ${<Link href="https://twitter.com/eskerinc">@EskerInc</Link>} and join the conversation on the Esker blog at ${<Link href="https://blog.esker.com/?utm_source=Visibility&utm_medium=MediaRelations&utm_campaign=PRs">blog.esker.com</Link>}.`
                ]
            ]
        }
    },
    aliaxis: {
        heading: "Aliaxis",
        subheading:"Optimizing ORDER MANAGEMENT for the improvement of customer service",
        headerImg: bgAliaxis,
        info: {
            heading: ["CHALLENGES", "SOLUTION", "RESULTS"],
            subheading: 
                [
                    "Inefficient ORDER MANAGEMENT impacting team productivity & customer satisfaction.",
                    "Streamlining & centralizing ORDER MANAGEMENT in one easy-to-use cloud interface.",
                    'Benefiting from a digital environment of “positive-sum” growth.'
                ],
            description: [
                [
                    "Prior to Esker, Aliaxis Latin America (LATAM) relied on an ORDER MANAGEMENT process where employees had to manually enter order data into SAP®, all while managing 460,000 different types of orders annually.",
                    'For Regional Business Process Owner Marcela Castillo Ugalde, the process created pain points for almost everyone involved. “The amount of time it took for a team member to enter a purchase order caused frustration not only for our customers but for our team who had to invest time in updating, correcting and entering orders in our ERP,” he said. “The process became even more tedious when orders were received with many lines of different materials and quantities.”',
                    "Aliaxis LATAM was motivated to find a digital solution that would expedite the ORDER MANAGEMENT process while helping Ugalde and her team improve the evaluation and quality of the order data within SAP."
                ],
                [
                    "With Esker, Aliaxis LATAM found the digital ORDER MANAGEMENT solution it was looking for; specifically, one that could seamlessly integrate into its existing SAP environment and automate the entry of customer orders so that the rest of the process (order entry, data tracking, communication, etc.) was optimized.",
                    '“Esker has become our primary order entry tool because of how it streamlines our process,” said Ugalde. Powered by AI technologies, Esker’s solution optimizes data recognition and verification of incoming orders so that bottlenecks like manual data entry (and the associated errors) are eliminated. Ugalde added: “Accelerating order entry has allowed more time for order follow-up activities, which provide real value to customers and more fulfillment for our team.”',
                    'One of the most impactful features of Esker’s ORDER MANAGEMENT solution was the visibility it offers through customizable dashboards and analytics. “Esker gives us the necessary alerts so that orders aren’t duplicated or processed with an unusual quantity,” said Ugalde. “We also get reports that provide data on our team’s performance.”'
                ],
                [
                    'While many digital solutions can only offer one-dimensional benefits (e.g., faster processing, reduced costs, etc.), Esker’s ORDER MANAGEMENT solution has enabled Aliaxis LATAM to create an environment of true “positive-sum” growth — that is, where benefits extend to virtually every stakeholder without coming at the expense of another. In this case, implementing Esker resulted in a positive impact for Aliaxis LATAM as a business, for its customer service team and for its customer base.',
                    '“Esker has truly transformed our work environment and has helped us live up to the title of ‘customer service,’” said Ugalde. “For our staff, it’s been very exciting to see the gains they’ve made and how they challenge themselves to continue improving with the help of Esker’s tool.”',
                    "Once all the 10 countries in LATAM are fully up and using automated ORDER MANAGEMENT, Aliaxis LATAM has plans to expand its use of the solution to include EDI capabilities as well."
                ]

            ]
        }
    },
    emasal: {
        heading: "Emasal",
        subheading: "Achieving AP efficiency with an End-To-End, AI-Based automation solution",
        headerImg: bgEmasal,
    },
    domingo_alonso: {
        heading: "Domingo Alonso",
        subheading: "Achieving AP efficiency with an End-To-End, AI-Based automation solution",
        headerImg: bgDomingoAlonso,
        info: {
            heading: ["CHALLENGES", "SOLUTION", "RESULTS"],
            subheading:
                [
                    "Streamlining and simplifying manually intensive administrative work",
                    "Supporting the AP team and suppliers through automation",
                    "Satisfying employees and customers"
                ],
            description: [
                [
                    "In 2018, Domingo Alonso’s financial department was looking for an automation solution to improve its supplier relationships, optimize payments, organize its accounting department and centralize incoming invoices. The Group also wanted to eliminate paper in its offices, which would accelerate searches for digital files and streamline invoicing.",
                    "Prior to implementing Esker’s solution, Domingo Alonso’s AP process involved manually scanning each of the 120,000 supplier invoices the Group receives each year and uploading them into the company’s Microsoft Dynamics NAV ERP system. This inefficient and time-consuming process was often a source of errors and resulted in poor invoice visibility.",
                    "The main objective of the automation project was to reduce process complexity and automate many of the manual tasks in order to achieve greater efficiency. Several specific workflows were mapped and created based on various use cases."
                ],
                [
                    "Esker was selected to streamline the entire AP workflow, from invoice reception to payment. The successful implementation of the Esker solution enabled Domingo Alonso to significantly increase its productivity by automating manual tasks and achieve real-time visibility of their AP processes, all in one place. The collected and organized data has helped the Group better understand its finances, redistribute workloads internally and leverage reliable KPIs to make better decisions.",
                    "Solution functionalities such as global filters that allow for searching by company or business unit and subsequently optimizing operations are also greatly appreciated. Esker’s dashboard shows users only the information relevant to them, displaying only the pertinent invoices, KPIs, views, counters and reports.",
                    "Additionally, a customized portal with the Group’s corporate branding was made available to suppliers, enabling them to access payment information on their own. This provides value both to the supplier, who has access to invoice status and payment information, as well as to Domingo Alonso, by easing their workload."
                ],
                [
                    "Implementing Esker’s ACCOUNTS PAYABLE solution has allowed Domingo Alonso Group to centralize invoice management and reception in one place. Standardizing processes and automating manual tasks resulted in increased productivity and efficiency. The project of implementing Esker’s solution to all of the Group’s business areas was not an easy task, as their activities differ depending on the sectors in which they operate. Nonetheless, Esker easily adapted to each of them and created specific workflows based on their various use cases. Customized deployment in sprints allowed for a convenient and streamlined implementation of the solution."
                ]
            ]
        }
    }
};

export default dataRealCaseStudy;