import background from '../images/backgrounds/partners.jpg'

import Esker from '../images/logos_partners/Esker.jpg'
import BDO from '../images/logos_partners/BDO.jpg'
import Calipso from '../images/logos_partners/Calipso.jpg'
import Asug from '../images/logos_partners/Asug.jpg'
import Binova from '../images/logos_partners/Binova.jpg'
import ABT from '../images/logos_partners/ABT Consultant.jpg'
// import BTCI from '../images/logos_partners/BTCI.jpg'
//Esker, BDO, LealIt, BTCI, Calipso.
export var dataPartnersPage = {
    headerHeading: "Partners",
    headerSubheading: "",
    headerImg: background,
    carousel: {
        heading: "OUR PARTNERS",
        subheading: "STRENGTHENING CUSTOMER RELATIONSHIPS & EXCEEDING AUTOMATION GOALS",
        description: "With a history of more than 15 years in the market, today we can ensure that our customers see all our effort reflected in an increase in productivity and reliability in both operational and management procedures.",
        headingSpan: " Customers",
        images: [
            {
                id: 1,
                src: Esker,
                alt: "Image 1"
            },
            {
                id: 2,
                src: BDO,
                alt: "Image 2"
            },
            {
                id: 3,
                src: Calipso,
                alt: "Image 3 "
            },
            {
                id: 4,
                src: Asug,
                alt: "Image 4"
            },
            {
                id: 5,
                src: Binova,
                alt: "Image 5"
            },
            {
                id: 6,
                src: ABT,
                alt: "Image 5"
            }
        ]
    }
};

export default dataPartnersPage;
