import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import img from '../../images/backgrounds/procure-to-pay.jpg'


const Container = styled.div`
  ${tw`relative  bg-bottom bg-cover h-96  content-center pt-24`}
  
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-red-800 bg-gray-700 opacity-75 `;

const HeroContainer = tw.div`relative  h-full flex flex-col z-10`;
//py-20 sm:pt-4
const Content = tw.div` flex flex-1 flex-col justify-center items-center content-center`;
//sm:pt-24
const Heading = styled.h1`
  ${tw`text-4xl sm:text-5xl sm:text-4xl lg:text-4xl xl:text-5xl text-center font-sans font-semibold text-white leading-snug z-20 sm:tracking-wider uppercase`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
//tracking-wide
//tracking-wider
//tracking-widest
const Subheading = styled.h1`
${tw`text-xl sm:text-2xl text-center sm:text-2xl lg:text-2xl xl:text-3xl text-white normal-case `}`
  ;

const PrimaryButton = tw(PrimaryButtonBase)`text-xs my-1 sm:text-lg inline-block bg-red-700 hocus:bg-red-500 rounded-full mx-1 sm:mx-4 sm:w-auto`;

export default ({
  heading = "",
  subheading = "",
  imgSrc = img,
  buttonL = null,
  buttonR = null
}) => {

  return (
    <Container style={{backgroundImage: `url(${imgSrc})`}}>
      <OpacityOverlay />
      <HeroContainer>
        <Content>
          <div tw="z-20 bg-gray-800 p-4 opacity-100">
            <Heading>{heading}</Heading>
            {subheading && <Subheading >{subheading}</Subheading>}
            {buttonL && buttonR && <div tw="text-center sm:mt-6 sm:mb-2">
              {buttonL && <PrimaryButton><a href="procure-to-pay">{buttonL}</a></PrimaryButton>}
              {buttonR && <PrimaryButton><a href="order-to-cash">{buttonR}</a></PrimaryButton>}
            </div>}
          </div>
        </Content>
      </HeroContainer>
    </Container>
  );
};
