import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import "./components/hero/Header.css"
//Data
import dataMainPage from './data/dataMainPage'

//Components
import Header from 'components/hero/HeaderLandingPage'
import About from "components/features/TwoColSingleFeatureWithStats4.js";
import ProfessionalServices from "components/features/CardsWithServices";
import Customers from "components/cards/PortfolioTwoCardsWithImage.js";
import CarouselComponent from "components/carousel/CarouselComponent";
import ContactUs from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/RequestFooter";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => (
  

  <StyledDiv>
    <Header 
      heading = {
        <>
          <span className="header-title -mb-8">
            { dataMainPage.header.heading }
          </span>
          <br/>
          <span tw="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:-mb-8">
            { dataMainPage.header.headingSpan }
          </span>
        </>
      }
      subheading = { dataMainPage.header.subheading }
    />

    <About
      heading={    
        <>
          { dataMainPage.about.heading }
          <span tw="text-red-700">
            { dataMainPage.about.headingSpan }
          </span>
        </>
      }
      description = { dataMainPage.about.description }
      descriptionDos = { dataMainPage.about.descriptionDos }
      descriptionTres = { dataMainPage.about.descriptionTres }
      descriptionCuatro = { dataMainPage.about.descriptionCuatro }
      imageSrc = { dataMainPage.about.imageSrc }
      />

    <ProfessionalServices
      heading = {
        <>
          { dataMainPage.professionalServices.heading }
          <span tw="text-red-700">
            { dataMainPage.professionalServices.headingSpan }  
          </span> 
        </>
      }
      cards = { dataMainPage.professionalServices.cards }
    />

    <Customers
      heading = {
        <>
          { dataMainPage.customers.heading }
          <span tw="text-red-700">
            { dataMainPage.customers.headingSpan }
          </span>
        </>
      }
      description = { dataMainPage.customers.description }
      linkText = { dataMainPage.customers.linkText }
      cardLinkText = { dataMainPage.customers.cardLinkText }
      textOnLeft = { dataMainPage.customers.textOnLeft }
      cards = { dataMainPage.customers.cards }
    />

    <CarouselComponent
      heading = {
        <>
          { dataMainPage.carousel.heading }
          <span tw="text-red-700">
            { dataMainPage.carousel.headingSpan }
          </span>
        </>
      }
      imgs = { dataMainPage.carousel.images }
    />

    <ContactUs
      subheading=""
      heading = {
        <>
          { dataMainPage.contactUs.heading } 
          <span tw="text-primary-700"> { dataMainPage.contactUs.headingSpan }</span>
        </>
      }
      description = { dataMainPage.contactUs.description }
      descriptionDos = { dataMainPage.contactUs.descriptionDos }
      submitButtonText = { dataMainPage.contactUs.submitBUttonText }
      formAction = { dataMainPage.contactUs.formAction }
      formMethod = { dataMainPage.contactUs.formMethod }
      textOnLeft = { dataMainPage.contactUs.textOnLeft }
    />
    <Footer
      copyrightText = { dataMainPage.footer.copyrightText }
      logo = { dataMainPage.footer.logo }
    />
  </StyledDiv>
);
