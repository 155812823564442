import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/request/logoRequestBlanco-Transparente.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import {ruta} from '../../helpers/rutaServidor'
import Dropdown from "components/navbar/Dropdown.js";
import Dropdown2 from "components/navbar/Dropdown2.js";

const Header = tw.header`
  flex items-center
  content-center
`;
export const ContainerLinks = tw.div`relative`;

//Disposicion de las partes del nav
export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 items-center justify-center 
`;
// justify-around 
export const NavLinks = tw.div`inline-block px-6 pt-0 xl:px-6 mr-12`;

export const NavListItem = tw.li`flex items-center h-12`;
export const SubNavListItem = tw.li`flex items-center h-10`;

export const NavLink = tw.a`
  text-xl sm:text-sm lg:text-sm
  font-medium xl:tracking-wide transition duration-300
  border-transparent 
   lg:p-4
`;
export const SubNavLink = tw.a`
  text-lg sm:text-sm lg:text-sm
  font-normal transition duration-300
  border-transparent pl-0
  h-12
`;
// lg:mx-6 lg:my-0
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  text-red-800
  lg:px-8 lg:py-3 rounded lg:bg-gray-100
  border-none
  lg:hocus:bg-red-800 
  font-bold
  inline-block  
`;
// export const PrimaryLink = tw(NavLink)`
//   lg:mx-0
//   text-red-800
//   px-8 py-3 rounded bg-gray-100
//   border-none
//   hocus:bg-red-800 
//   inline-block  
// `;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center border-b-0 pr-8`};

  img {
    ${tw`lg:w-64 w-40 `}
  }
`;

//Navbar Container
export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between z-20 pr-6`; 

//Links Navbar
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mt-24 h-screen text-left rounded-none text-gray-900 bg-white`} 
  ${NavLinks} {
    ${tw`flex flex-col items-start`}
  }
`);

//Hamburger Menu
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;

const Divider = tw.div`my-1 mx-auto border-b-2 border-gray-400 w-80`;

//justify-between px-6 2xl:px-80
export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {

    const WIN_WIDTH =  window.innerWidth;

    const [click, setClick] = useState(false);


    const [dropdown, setDropdown] = useState(false);
    const [dropdown2, setDropdown2] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const onMouseEnter = () => {
        if (window.innerWidth < 1024) {
        setDropdown(false);
        } else {
        setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 1024) {
        setDropdown(false);
        } else {
        setDropdown(false);
        }
    };

    const onMouseEnter2 = () => {
      if (window.innerWidth < 1024) {
      setDropdown2(false);
      } else {
      setDropdown2(true);
      }
  };

  const onMouseLeave2 = () => {
      if (window.innerWidth < 1024) {
      setDropdown2(false);
      } else {
      setDropdown2(false);
      }
  };

  // const rutaServidor = "/requestsa";
  const rutaServidor = ruta; 

  const defaultLinks = [
    <ContainerLinks>
      <NavLinks key={1}>
      <ul tw="lg:flex lg:h-20">
        <li
          tw="flex items-center h-20"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}   
        >
          <NavLink
              href={ rutaServidor + "/professional-services" }
          >
            Professional Services
          </NavLink>
            {dropdown && <Dropdown />}
        </li>

        <li
          tw="flex items-center h-20"
          onMouseEnter={onMouseEnter2}
          onMouseLeave={onMouseLeave2}     
        >
          <NavLink href={ rutaServidor + "/esker-solutions" }
          >
            Esker Solutions
          </NavLink>
            {dropdown2 && <Dropdown2 />}
        </li>

        <li tw="flex items-center h-20">
          <NavLink href={ rutaServidor + "/customers" }
          >
            Customers
          </NavLink>
        </li>

        <li tw="flex items-center h-20">
          <NavLink href={ rutaServidor + "/partners" }
          >
            Partners
          </NavLink>
        </li>

        <li tw="flex items-center h-20">
          <NavLink href={ rutaServidor + "/news" }
          >
            News
          </NavLink>
        </li>
      </ul>
            
    </NavLinks>
    </ContainerLinks>
  ];

  const mobileLinks = [
  <ContainerLinks>
    <NavLinks tw="pt-8 px-10" key={1}>
      <ul tw="lg:flex lg:h-20">
        <NavListItem
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}   
        >
          <NavLink
              href={ rutaServidor + "/professional-services" }
          >
            Professional Services
          </NavLink>
          
        </NavListItem>
        <div tw="pl-6">
            <SubNavListItem
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}   
            >
              <SubNavLink
                  href={ rutaServidor + "/professional-services/sap-developing-services" }
              >
                SAP Developing Services
              </SubNavLink>
              
            </SubNavListItem>
            <SubNavListItem
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}   
            >
              <SubNavLink
                  href={ rutaServidor + "/professional-services/fullstackdevelopment" }
              >
                FULL STACK Development
              </SubNavLink>
            </SubNavListItem>
            <SubNavListItem
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}   
            >
              <SubNavLink
                  href={ rutaServidor + "/professional-services/addon-development" }
              >
                ADD-ON Development
              </SubNavLink>
            </SubNavListItem>
            <SubNavListItem
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}   
            >
              <SubNavLink
                  href={ rutaServidor + "/professional-services/it-consulting" }
              >
                IT Consulting
              </SubNavLink>
            </SubNavListItem>
        </div>
        <Divider />
        <NavListItem
          onMouseEnter={onMouseEnter2}
          onMouseLeave={onMouseLeave2}     
        >
          <NavLink href={ rutaServidor + "/esker-solutions" }>
            Esker Solutions
          </NavLink>
        </NavListItem>
        <div tw="pl-6">
            <SubNavListItem
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}   
            >
              <SubNavLink
                  href={ rutaServidor + "/procure-to-pay" }
              >
                PROCURE-TO-PAY
              </SubNavLink>
              
            </SubNavListItem>
            <SubNavListItem
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}   
            >
              <SubNavLink
                  href={ rutaServidor + "/order-to-cash" }
              >
                ORDER-TO-CASH
              </SubNavLink>
            </SubNavListItem>
            <li
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}   
            >
            </li>
        </div>
        <Divider />
        <NavListItem>
          <NavLink href={ rutaServidor + "/customers" }>
            Customers
          </NavLink>
        </NavListItem>
        <Divider />
        <NavListItem>
          <NavLink href={ rutaServidor + "/partners" }>
            Partners
          </NavLink>
        </NavListItem>
        <Divider />
        <NavListItem>
          <NavLink href={ rutaServidor + "/news" }>
            News
          </NavLink>
        </NavListItem>
        <Divider />
        <NavListItem>
          <PrimaryLink href="mailto:info@request.com.ar">
            Contact Us
          </PrimaryLink>
        </NavListItem>
      </ul>    
    </NavLinks>
  </ContainerLinks>
  ];
  const primaryLink = (
    <NavLinks key={2}>
      <PrimaryLink href="mailto:info@request.com.ar">
        Contact Us
      </PrimaryLink>
    </NavLinks>
  );

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo"/>
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks>
          {logoLink}
          {defaultLinks}
          {primaryLink}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        {/** valores normales: x: "150%", display:"none" valores para abrir menu permanentemente x:0% , display "block"*/}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {mobileLinks}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar}  className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-8 h-8" /> : <MenuIcon tw="w-8 h-8" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
