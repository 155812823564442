import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//Componentes
import Home from 'components/hero/HeaderPages'
import Info from "components/cards/Info";
import InfoComponent from "components/cards/InfoComponent";
import Footer from "components/footers/RequestFooter";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light";

import dataNews from "data/dataNews";
import dataMainPage from "data/dataMainPage";


const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full bg-red-800 fixed h-24 w-screen z-20 p-2 shadow-xl text-gray-400`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` hover:border-gray-300 hover:text-white`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 `}`;

  const Link = styled.a`
  ${tw`text-blue-500 hocus:border-b-2 hocus:border-blue-400`}`

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => {

  return (
    <StyledDiv>
      <Container>
        <StyledHeader />
        <Home
          heading = { dataNews.tripters.heading }
          subheading = { dataNews.tripters.subheading }
          imgSrc={ dataNews.tripters.headerImg }
        />
        <div tw="py-4 md:py-12">
            <InfoComponent
                // title = { dataNews.tripters.info.title }
                // heading = { dataNews.tripters.info.heading }
                // subheading = { dataNews.tripters.info.subheading }
                subheading = { dataNews.tripters.info.subheading }
                description = { dataNews.tripters.info.description } 
            />
            <Info
                description= {
                <div tw="mx-5">
                    For more information on Tripters, visit <Link href="https://tripters.com">https://tripters.com</Link>
                </div>
                }
            />
        </div>
        <br/>
        <Footer />
      </Container>
    </StyledDiv>
  );
};
