import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";


//estilos para 3 tarjetas
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-12 lg:py-24`;

const ThreeColumn = tw.div`flex flex-col items-center justify-center`;
const Column = tw.div`mt-16 w-80 sm:w-144  items-center justify-center`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const HeadingTitle = tw(SectionHeading)`w-full text-center leading-tight`;

const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`lg:mx-4 xl:mx-4 max-w-xl flex flex-col h-full`;

const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-96 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4 p-2`;

const CardHeader = tw.div`flex justify-between items-center  h-12`;
const CardCompany = tw.div`text-primary-700 max-w-xs font-bold text-xl w-1/3`;
const CardType = tw.div`font-semibold text-sm text-left text-gray-600 w-1/2 pl-12 md:pl-20`;

const CardTitle = tw.h5`text-2xl mt-3 font-semibold text-center`;

const CardAction = tw(PrimaryButtonBase)`w-full mt-4`;

export default ({
  subheading = "",
  heading = "",
  description = "",
  linkText = "",
  cardLinkText = "",
  cards = null,
}) => {
  
  return (
    <Container>
      <Content>
          <HeadingInfoContainer>
            <HeadingTitle>{heading}</HeadingTitle>
            <Subheading>{subheading}</Subheading>
            {description && <HeadingDescription>{description}</HeadingDescription>}
            {linkText && <PrimaryLink>
              {linkText}
            </PrimaryLink>}
          </HeadingInfoContainer>
        <ThreeColumn>
          
          {cards.map((card, index) => (
            <Column key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.company}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  {card.salto ? <div tw="pt-1"><br/></div> : <></>}
                  
                </CardText>
                  <a href={card.buttonLink}><CardAction>{cardLinkText}</CardAction></a>
              </Card>
            </Column>
          ))}

        </ThreeColumn>
      </Content>
    </Container>
  );
};
