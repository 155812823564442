import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

//NECESARIO PARA RENDERIZAR LOS COMPONENTES

//REACT-ROUTER COMPONENTES
import { 
  BrowserRouter, 
  Routes, 
  Route, 
} from "react-router-dom";

import {ruta} from './helpers/rutaServidor'

//LANDING PAGE
import RequestLandingPage from "./RequestLandingPage";

//PAGINAS
import AboutUs from "pages/AboutUs";
import ProfesionalServices from "pages/ProfesionalServices";
import EskerSolutions from "pages/EskerSolutions";
import Customers from "pages/Customers";
import Partners from "pages/Partners";

//PAGINAS Professional Services
import SAPDeveloping from "pages/ProfessionalServices/SAPDeveloping";
import FullStackDevelopment from "pages/ProfessionalServices/FullStackDevelopment";
import AddOnDevelopment from "pages/ProfessionalServices/AddOnDevelopment";
import ITConsulting from "pages/ProfessionalServices/ITConsulting";

//PAGINAS Esker Solutions
import ProcureToPay from './pages/EskerSolutions/ProcureToPay'
import OrderToCash from 'pages/EskerSolutions/OrderToCash'
import Siemens from "pages/RealCaseStudy/Siemens";
import Aliaxis from "pages/RealCaseStudy/Aliaxis";
import DomingoAlonso from "pages/RealCaseStudy/DomingoAlonso";
import Emasal from "pages/RealCaseStudy/Emasal";
import News from "pages/News";
import Tripters from "pages/News/Tripters";



export default function App() {

  const rutaServidor = ruta;

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ rutaServidor } element={<RequestLandingPage />} />
        <Route path={ rutaServidor + "/about" } element={<AboutUs />} />
        <Route path={ rutaServidor + "/professional-services" } element={<ProfesionalServices />} />
        {/* ProfessionalServices pestañas */}
          <Route path={ rutaServidor + "/professional-services/sap-developing-services" } element={<SAPDeveloping />}/>
          <Route path={ rutaServidor + "/professional-services/fullstackdevelopment" } element={<FullStackDevelopment />}/>
          <Route path={ rutaServidor + "/professional-services/addon-development" } element={<AddOnDevelopment />}/>
          <Route path={ rutaServidor + "/professional-services/it-consulting" } element={<ITConsulting />}/>

        <Route path={ rutaServidor + "/esker-solutions" } element={<EskerSolutions />} />
        {/* EskerSolutions pestañas */}
          <Route path={ rutaServidor + "/procure-to-pay" } element={<ProcureToPay />}/>
          <Route path={ rutaServidor + "/order-to-cash" } element={<OrderToCash />}/>
        
        <Route path={ rutaServidor + "/customers" } element={<Customers />} />
        <Route path={ rutaServidor + "/partners" } element={<Partners />} />
        <Route path={ rutaServidor + "/news" } element={<News />} />
        {/* <Route path={ rutaServidor + "/contact-us"} element={<ContactUs />}/> */}

        {/* RealCaseStudy pages */}
          <Route path={ rutaServidor + "/siemens-healthineers-case"} element={ <Siemens/>} />
          <Route path={ rutaServidor + "/aliaxis-case"} element={ <Aliaxis/>} />
          <Route path={ rutaServidor + "/emasal-case"} element={ <Emasal/>} />
          <Route path={ rutaServidor + "/domingo-alonso-case"} element={ <DomingoAlonso/>} />
        {/* News pages */}
          <Route path={ rutaServidor + "/tripters"} element={ <Tripters/>} />
          
      </Routes>
    </BrowserRouter>
  );
}


