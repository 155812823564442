import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import ResponsiveVideo from '../../helpers/ResponsiveVideoEmbed'

const Container = tw.div`relative `;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center  mx-auto`}
`;
const Heading = tw(SectionHeading)`w-full text-center font-bold text-2xl sm:text-left mb-4 uppercase max-w-screen-lg p-0`;
const Subheading = tw(SubheadingBase)`w-full text-left font-bold text-lg text-left mb-4 normal-case max-w-screen-lg text-gray-800`;
const Description = tw(SectionDescription)`w-full text-left max-w-screen-lg mt-0`;

const Link = styled.a`
${tw`text-blue-500 hocus:border-b-2 hocus:border-blue-400`}`


export default ({
  heading = "",
  description = "",

}) => {


  return (
    <Container>
      <ThreeColumnContainer>
        <br/>
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
      </ThreeColumnContainer>
    </Container>
  );
};
