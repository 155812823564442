import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import ResponsiveVideo from '../../helpers/ResponsiveVideoEmbed'

const Container = tw.div`relative  mx-5`;

const ColContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full max-w-screen-sm`;
const Subheading = tw(SubheadingBase)`w-full font-bold sm:text-2xl text-center mb-4 uppercase`;
const Description = tw(SectionDescription)`w-full text-center max-w-screen-lg mt-0`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/3 lg:w-1/2 flex flex-col justify-center`}
`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideo)`
  ${tw`rounded w-64 mx-auto sm:w-full `}
  iframe {
    ${tw``}
  }
`;

export default ({
    heading = "",
    subheading = "", 
    description = "",
    urlVideo = null,
}) => {

  return (
    <Container>
      <ColContainer>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        <Column>
          <StyledResponsiveVideoEmbed url={urlVideo}/>
        </Column>
      </ColContainer>
    </Container>
  );
};
