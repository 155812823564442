import {ruta} from '../../helpers/rutaServidor'
//
const rutaServidor = ruta;

//
export const MenuItem = {
    professionalServices: [
        // {
        // title: 'SAP Developing Services',
        // path: `${rutaServidor + '/professional-services/sap-developing-services'}`,
        // cName: 'dropdown-link'
        // },
        {
        title: 'FULL STACK Development',
        path: `${rutaServidor + '/professional-services/fullstackdevelopment'}`,
        cName: 'dropdown-link'
        },
        {
        title: 'ADD-ON Development',
        path: `${rutaServidor + '/professional-services/addon-development'}`,
        cName: 'dropdown-link'
        },
        // {
        // title: 'IT Consulting',
        // path: `${rutaServidor + '/professional-services/it-consulting'}`,
        // cName: 'dropdown-link'
        // }
    ],
    eskerSolutions: [
        {
        title: 'PROCURE-TO-PAY',
        path: `${rutaServidor + '/procure-to-pay'}`,
        cName: 'dropdown-link'
        },
        {
        title: 'ORDER-TO-CASH',
        path: `${rutaServidor + '/order-to-cash'}`,
        cName: 'dropdown-link'
        }
    ]
};

