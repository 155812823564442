import background from '../images/backgrounds/procure-to-pay.jpg'

import reiLogo from "../images/add_ons_titles/logo_rei.png"
import cotLogo from "../images/add_ons_titles/logo_cot.png"
import apocriphalLogo from "../images/add_ons_titles/logo_apocriphal.jpg"

import rei from '../images/add_ons/factura_electronica.png'
import cot from '../images/add_ons/cot.jpg'
import apocriphal from '../images/add_ons/factura_apocrifa.png'




export var dataProfessionalServices = {
    full_stack: {
        headerHeading: "FULL STACK DEVELOPMENT",
        headerSubheading: "",
        headerImg: background,
        info: {
            full_stack_development: {
                heading: "FULL STACK ",
                headingSpan: "Development",
                description: "REQUEST  has strong technology expertise and combined knowledge on working in full stack development ranging from Front end Development, Middleware Integration, Backend Development, DevOps and AI. Our expertise in Full Stack Development Services are as follows."
            },
            cards: [
                {
                title: "FRONT-END ",
                span: "Development",
                description: "We ensure that our front-end development services integrate well with web services and other external technologies. Our front-end trained designers are to understand business logic and customer objectives, to deliver with unique and top-notch solutions.",
                list: []
                },
                { 
                title: "MIDDLEWARE ",
                span: "Development",
                description: "We ensure that our front-end development services integrate well with web services and other external technologies. Our front-end trained designers are to understand business logic and customer objectives, to deliver with unique and top-notch solutions.",
                list: []
                },
                {
                title: "BACKEND ",
                span: "Development",
                description: "Request has strong experience in backend development of following applications:",
                list: [
                    "Cloud Migration",
                    "Backend Modernization", 
                    "API Integration", 
                    "User Management", 
                    "Messaging Services", 
                    "Data Synchronization", 
                    "Data Storage", 
                    "Mobility", 
                    "Customer Relationship Management", 
                    "Platform Integration",
                ]
                }
            ],

        }
    },
    add_on: {
        headerHeading: "ADD-ON Development Services",
        headerSubheading: "",
        headerImg: background,
        info: {
            factura_electronica: {
                heading: "REI - Electronic Invoice for SAP",
                headingSpan: " 2000.",
                description: "REI (Request Electronic Invoice) is an electronic invoicing solution specially designed for SAP. This application simplifies the connection between SAP and the AFIP system allowing companies to issue electronic invoices.",
                descriptionDos: "It consists of 2 applications: the first, a MONITOR on the SAP side in charge of receiving invoices from SD and / or FI, processing them, managing CAE, accounting, sending emails and contemplating contingencies in the event of errors. The second application is a MIDDLEWARE developed in.NET in charge of receiving the orders of the MONITOR and managing the communication with the AFIP webservices.",
                descriptionTres: "",
                descriptionCuatro: "",
                imageLogo: reiLogo,
                imageSrc: rei,
            },
            cot: {
                heading: "COT - Electronic Delivery Note for SAP",
                headingSpan: " 2000.",
                description: 'As of 2006, Normative Provision "B" 32/06 came into force, by which ARBA obliges to protect the transfer or transport of goods in the provincial territory through a Transfer Operation Code (COT).',
                descriptionDos: "Yes, get your remittances with the COT printed automatically and without human intervention.",
                descriptionTres: "Our Gateway facilitates communication between your ERP and ARBA obtaining the COT in a transparent way without altering your usual operation.",
                descriptionCuatro: "",
                imageLogo: cotLogo,
                imageSrc: cot,
                listTitle: "How does it Work?",
                list: [
                    "At the time of dispatching goods, SAP invokes the COT-GATEWAY solution who establishes a VPN communication with ARBA and communicates the SAP remittance data necessary to obtain the C.O.T.",
                    "The ARBA webservice service returns a COT order status. This status is recorded in SAP on a monitor, through which all COT requests and their result are monitored, being able to reprocess any COT order with problems.",
                    "If the COT authorization is successful, the COT will be printed on the traditional shipment and the transport of the goods will begin.",
                    "If the transport is intercepted by ARBA at a checkpoint, it will be sufficient to present the COT printed on the consignment to validate the authorization of the transport.",
                    "The merchandise arrives at its destination in compliance with current regulations."
                ]
            },
            factura_apocrifa: {
                heading: "Apocryphal - Validation of Third Party Invoices for SAPs",
                headingSpan: " 2000.",
                description: 'Request developed a solution that allows validating the authenticity of Invoices issued by third parties with CAI, CAE and CAEA.Al enter a supplier invoice in SAP, it is verified in AFIP whether or not it is apocryphal through an automatic online connection developed by Request.',
                descriptionDos: 'The Tax Procedure Law 25.795 (B.O.17/11/2003) regulates in its article 33.1: "Taxpayers will be obliged to verify that the invoices or equivalent documents they receive for their purchases or locations, are duly authorized by the Federal Administration of Public Revenues."',
                descriptionTres: "In 2003 there was only the CAI to objectively validate a third-party voucher against AFIP, today with the implementation of the issuance of electronic vouchers with CAE and CAEA, the AFIP returns to the load requiring companies to control their purchase vouchers objectively. To this end, it makes available to the consumer manual consultation websites to carry out these validations.",
                descriptionCuatro: "REQUEST presents the VFA-Gateway solution, a tool capable of automating the process of consulting the veracity of purchase receipts before AFIP.",
                imageLogo: apocriphalLogo,
                imageSrc: apocriphal
            }
        }
    },
    
}

export default dataProfessionalServices;