import bgP2P from '../images/backgrounds/procure-to-pay.jpg'
import bgO2C from '../images/backgrounds/order-to-cash.jpg'

import videoEsker from '../vids/demo-1000px-v2.mp4'
import videoP2P from '../vids/website-demo-video-p2p.mp4'
import imgO2C from '../images/request/why_esker_laptop_mobile_2.png'

export var dataEskerSolutionsPages = {
    eskerSolutions: {
        home: {
            heading: "ESKER SOLUTIONS",
            subheading: "AI-Driven Solutions for the Digital Enterprise",
            imgSrc: bgP2P,
            buttonL: "PROCURE-TO-PAY",
            buttonR: "ORDER-TO-CASH"
        },
        videoSection: {
            heading: "Meet Esker’s Cloud Platform",
            subheading: "Powerfully Smart. Beautifully Simple.",
            description: "Whether you’re a finance leader or customer service professional, Esker equips you with the technology needed to eliminate tedious procure-to-pay (P2P) and order-to-cash (O2C) tasks — all from one easy-to-use interface and AI-powered cloud platform.",
            urlVideo: videoEsker,
        }
    },

    procureToPay: {
        home: {
            heading: "Procure-to-pay Automation",
            subheading: "Transforming the Way Businesses Purchase, Book and Pay.",
            imgSrc: bgP2P,
        },
        videoSection: {
            heading: " Suite for the fully networked enterprice",
            headingSpan: "P2P",
            description: "Offering a 360° view of supplier information, Esker’s automated platform spans the entire P2P process — equipping finance departments with AI and RPA technology and helping them effectively manage compliance, gain full process visibility, reduce staff workload and achieve true positive-sum growth for their company.",
            urlVideo: videoP2P,
        },
        cards: {
            cardsLeft: [
                {
                  title: "Supplier Management",
                  description: "Centralize vendor data while simplifying the supplier onboarding.",
                  url: "https://www.esker.com/business-process-solutions/procure-pay-process/supplier-management-automation/"
                },
                { 
                  title: "Contract Management",
                  description: "Drive new savings with improved contract visibility and compliance."
                },
                { 
                  title: "Procurement",
                  description: "Transform buying into an Amazon-like experience across the enterprise.",
                  url: "https://www.esker.com/business-process-solutions/procure-pay/automated-procurement-software/"
                }
              ],
            cardsRight: [
    
                { 
                  title: "AP Automation",
                  description: "Reduce invoicing costs and delays thanks to AI-based technology.",
                  url: "https://www.esker.com/business-process-solutions/procure-pay/accounts-payable-automation-software/"
                },
                { 
                  title: "Expense Management",
                  description: "Free up your finance team by automating hours of low-value admin tasks.",
                  url: "https://cloud.esker.com/fm/others/003-esker-procure-to-pay-expense%20management-solution-summary-us.pdf"
                },
                { 
                  title: "Payment & Supply chain financing",
                  description: "Automate payment approval workflow while securing discounts.",
                  url: "https://www.esker.com/technology-solutions/esker-pay/"
                },
              ]
        }
    },

    orderToCash: {
        home: {
            heading: "order-to-cash automation",
            subheading: "Uniting the People and Processes That Impact Customer Experience.",
            imgSrc: bgO2C,
        },
        infoSection: {
            heading: "A single suite for all your",
            headingSpan: " O2C ",
            headingContinuacion: "needs",
            description: "Whether it’s providing a 360° view over your customers’ behaviors through customer management or improving global speed and accuracy via AI and RPA technology, Esker’s integrated end-to-end platform is the digital foundation for all your O2C needs. What's more, your organization will have a stronger, more resiliant business model built on positive-sum growth.",
            imgSrc: imgO2C
        },
        cards: {
          cardsLeft: [
            {
              title: "Order Management",
              description: "Eliminate manual data entry by enabling automated order processing.",
              url: "https://www.esker.com/business-process-solutions/order-cash/order-processing-automation-software/"
            },
            { 
              title: "Credit Management",
              description: "Send & monitor credit apps while managing customers credit risks.",
              url: "https://www.esker.com/business-process-solutions/order-cash/accounts-receivable-management-software/credit-management/"
            },
            { 
              title: "Invoice delivery",
              description: "Deliver invoices according to customer preferences with 100% compliance.",
              url: "https://www.esker.com/business-process-solutions/order-cash/accounts-receivable-management-software/invoicing-software/"
            }
          ],

          cardsRight: [
            { 
              title: "Payment",
              description: "Allow customers to easily & securely make their payments online.",
              url: "https://www.esker.com/business-process-solutions/order-cash/accounts-receivable-management-software/payment/"
            },
            { 
              title: "Cash App & Deductions",
              description: "Streamline cash app & deductions with auto-matching & root-cause analysis.",
              url: "https://www.esker.com/business-process-solutions/order-cash/accounts-receivable-management-software/cash-application/"
            },
            { 
              title: "Collecions Management",
              description: "Reduce DSO with rule-based task lists & CRM-like data centralization.",
              url: "https://www.esker.com/business-process-solutions/order-cash/accounts-receivable-management-software/collections-management/"
            },
          ]
        }
    }
}

export default dataEskerSolutionsPages;